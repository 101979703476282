import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, FILTERS_CONTAINS, WEB_ANALYTICS_PPVF_EXCHANGE_RATES } from "@kraftheinz/common";
import _ from "lodash";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Forex Rates", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("div", [_c("div", { staticClass: "d-flex align-items-center" }, [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Forex Rates for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID", false);
    } } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Fin Year")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("text-input", { attrs: { "placeholder": "Fin Year", "value": _vm.finYearValue }, on: { "change": _vm.onFinYearChange } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Currency")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Currency", attrs: { "source": "currency", "placeholder": "Currency", "data-source": _vm.currencies, "value": _vm.selectedCurrency }, on: { "change": _vm.onCurrencyChange } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Rate Type")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "RateType", attrs: { "source": "rateType", "placeholder": "Rate Type", "default-active-first-option": "", "data-source": _vm.rateTypes, "value": _vm.selectedRateType }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "RateType", false);
    } } })], 1)]), _c("div", { staticClass: "mt-2" }, [_vm._v("Note: Budget Rates are setup in the PPV Actuals model")])])];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "RateType", attrs: { "data-index": "rateType", "title": "Rate Type" } }), _c("text-field", { key: "Currency", attrs: { "data-index": "currency", "title": "Currency" } }), _c("text-field", { key: "IntStartDate", attrs: { "data-index": "intStartDate", "title": "Start Date", "parse": _vm.parseDate } }), _c("text-field", { key: "IntEndDate", attrs: { "data-index": "intEndDate", "title": "End Date", "parse": _vm.parseDate } }), _c("text-field", { key: "Rate", attrs: { "data-index": "rate", "title": "Rate" } })], 1);
};
var staticRenderFns$1 = [];
var ExchangeRates_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-7767067f]{width:160px}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ExchangeRates",
  inject: ["createRoute", "crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      apiUrl: apiUrl$1,
      currencyAdvancedFilter: []
    };
  },
  computed: {
    finYearValue() {
      const statusFilter = this.crud.getQueryString("finyear");
      return statusFilter ? statusFilter.value : this.$moment().year() + 1;
    }
  },
  created() {
    this.crud.setQueryString("finyear", this.finYearValue);
    this.getCountries();
    this.getCurrency();
    this.getRateType();
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return;
      return this.$moment(`${value}`).format("YYYY/MM/DD");
    },
    onAddClick() {
      this.$router.push(this.createRoute);
    },
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Rate?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Rate?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/input-forex-rates/${record.rateID}`, {
          CountryID: record.countryID,
          Currency: record.currency,
          RateType: record.rateType,
          IntStartDate: record.intStartDate,
          IntEndDate: record.intEndDate,
          Rate: record.rate,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    },
    fetchList: _.debounce(function() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList(this.customUrl, true, true, this.customQuery);
    }, 400),
    onFinYearChange: _.debounce(function(value) {
      if (value) {
        this.crud.setQueryString("finyear", value);
        this.fetchList();
        return;
      }
      this.crud.deleteQueryString("finyear");
      this.fetchList();
    }, 400),
    onCurrencyChange(e) {
      this.crud.setFilter("Currency", {
        operator: FILTERS_CONTAINS,
        value: e.value
      });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7767067f", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ExchangeRates = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.input-forex-rates", "api-url": _vm.apiUrl, "create-route": "/web-analytics/exchange-rates/create", "edit-route": "/web-analytics/exchange-rates/:id", "page": _vm.page } }, [_c("exchange-rates")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ExchangeRates
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_EXCHANGE_RATES,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-forecast",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "exchange-rates",
          title: "Maintain Forex Rates",
          path: "/web-analytics/exchange-rates"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
